import { dataChartsServices } from "@common/data/content/service-cards";
import Layout from "@components/layout";
import SEO from "@components/seo";
import ServiceCardList from "@components/UI-Elements/Cards/ServiceCardList";
import React from "react";

const title = "Daten & Charts";
const description = "Diese Dashboards and Tools, sollen für ein besseres Verständnis über Bitcoin und den Markt sorgen.";
const CryptoLinks: React.FC = () => {
  return (
    <Layout prose>
      <SEO title={title} description={description} />
      <ServiceCardList
        title={title}
        titleProps={{ level: 1 }}
        subtitle={description}
        sectionProps={{ className: "py-0" }}
        services={dataChartsServices}
        serviceColor="bg-blue-600"
      />
    </Layout>
  );
};

export default CryptoLinks;
